import React from "react"
import Header from "../components/header"
import ImageContent from "../components/imageContent"
import Footer from "../components/footer"
import Camp from "../components/camp"

export default function CampPage() {
  return (
    <>
      <Header />
      <ImageContent className="flex items-start" image="/assets/5.webp">
        <div className="z-10 mt-20 text-6xl uppercase text-azur mx-14 md:mx-20">
          <h1 className="font-fontspring-bold">
            NEVIN GALMARINI – SNOWBOARD CARVING CAMP
          </h1>
        </div>
      </ImageContent>
      <Camp />
      <Footer />
    </>
  )
}
